@import 'styles/core/typography';

.container {
  position: relative;
  display: flex;
  align-items: center;
  align-self: center;

  .searchLabel {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .leading {
    position: absolute;
    left: 0;
  }

  .input {
    @include body-medium;
    letter-spacing: 0;
    width: 100%;
    outline: none;
    border: unset;
    background-color: transparent;
    border-bottom: 1px solid var(--color-on-bg-secondary);
    padding: var(--space-size-1);
    border-radius: 0;

    &.leading {
      padding-left: var(--space-size-3);
    }

    &.trailing {
      padding-right: var(--space-size-3);
    }
  }

  /* clears the ‘X’ from Internet Explorer */
  input[type='search']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  input[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  /* clears the ‘X’ from Chrome */
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }

  .trailing {
    position: absolute;
    right: 0;
  }
}
