@import 'styles/utils/mixins';
@import 'styles/core/typography';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: var(--space-size-1) calc(var(--space-size-2) + var(--space-size-0-5));

  @media screen and (min-width: $breakpoint-sm) {
    padding: 1rem var(--space-size-4) 1rem 0;
  }
  @media screen and (min-width: $breakpoint-md) {
    padding: var(--space-size-2) var(--space-size-5) var(--space-size-2) 0;
  }

  &.paddingLeft {
    @media screen and (min-width: $breakpoint-sm) {
      padding-left: var(--space-size-4);
    }

    @media screen and (min-width: $breakpoint-lg) {
      padding-left: var(--space-size-5);
    }
  }

  .title {
    color: var(--color-on-bg-secondary);
    @include heading-m-medium;
    text-transform: capitalize;
  }

  .titleHighlighted {
    color: var(--color-on-bg-primary);
    text-transform: capitalize;
  }

  .buttonSearch {
    color: var(--color-on-bg-primary);
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    span {
      margin-left: 1.2rem;
      display: none;
      @media screen and (min-width: $breakpoint-sm) {
        display: block;
      }
    }
    @include label-s-medium;
    @media screen and (min-width: $breakpoint-sm) {
      @include label-m-medium;
    }
    @media screen and (min-width: $breakpoint-md) {
    }
    @media screen and (min-width: $breakpoint-lg) {
      @include label-l-medium;
    }
  }

  .iconSearch {
    width: var(--space-size-3);
    height: var(--space-size-3);
  }
}
